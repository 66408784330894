@import '../../style/themes/index';
@import '../../style/mixins/index';

@tag-prefix-cls: ~'@{ant-prefix}-tag';

.@{tag-prefix-cls} {
  .reset-component;

  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: @tag-font-size;
  line-height: 20px;
  white-space: nowrap;
  background: @tag-default-bg;
  border: @border-width-base @border-style-base @border-color-base;
  border-radius: @border-radius-base;
  cursor: default;
  opacity: 1;
  transition: all 0.3s @ease-in-out-circ;

  &:hover {
    opacity: 0.85;
  }

  &,
  a,
  a:hover {
    color: @tag-default-color;
  }

  > a:first-child:last-child {
    display: inline-block;
    margin: 0 -8px;
    padding: 0 8px;
  }

  .@{iconfont-css-prefix}-close {
    .iconfont-size-under-12px(10px);

    margin-left: 3px;
    color: @text-color-secondary;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s @ease-in-out-circ;

    &:hover {
      color: @heading-color;
    }
  }

  &-has-color {
    border-color: transparent;
    &,
    a,
    a:hover,
    .@{iconfont-css-prefix}-close,
    .@{iconfont-css-prefix}-close:hover {
      color: @text-color-inverse;
    }
  }

  &-checkable {
    background-color: transparent;
    border-color: transparent;
    &:not(&-checked):hover {
      color: @primary-color;
    }
    &:active,
    &-checked {
      color: @text-color-inverse;
    }
    &-checked {
      background-color: @primary-6;
    }
    &:active {
      background-color: @primary-7;
    }
  }

  &-hidden {
    display: none;
  }

  // mixin to iterate over colors and create CSS class for each one
  .make-color-classes(@i: length(@preset-colors)) when (@i > 0) {
    .make-color-classes(@i - 1);
    @color: extract(@preset-colors, @i);
    @lightColor: '@{color}-1';
    @lightBorderColor: '@{color}-3';
    @darkColor: '@{color}-6';
    &-@{color} {
      color: @@darkColor;
      background: @@lightColor;
      border-color: @@lightBorderColor;
    }
    &-@{color}-inverse {
      color: @text-color-inverse;
      background: @@darkColor;
      border-color: @@darkColor;
    }
  }

  .make-color-classes();
}

@font-size-base: 12px;@font-size-lg: 14px;@font-size-sm: 10px;@primary-color: #1794FF;@text-color: #202224;@info-color: #0057BE;@success-color: #25A032;@error-color: #E40D0D;@heading-color: #1B1E24;@font-family: "Inter", -apple-system, system-ui, BlinkMacSystemFont, sans-serif, "Segoe UI", Roboto, Ubuntu;@border-radius-base: 5px;@table-border-radius-base: 5px;@table-header-bg: #F9FBFC;@input-height-base: 42px;@input-height-lg: 53px;@input-height-sm: 24px;@input-placeholder-color: #778CA2;@input-color: #202224;@input-border-color: #E4E9EF;@input-bg: #fff;@btn-font-weight: 700;@btn-border-radius-base: 4px;@btn-primary-bg: #2747A8;@btn-primary-border: #2747A8;@btn-default-color: #2747A8;@btn-default-border: #CDD2E0;@btn-disable-color: #F0F2F6;@btn-disable-bg: #CDD2E0;@btn-disable-border: #CDD2E0;@btn-height-base: 38px;@btn-height-lg: 44px;@btn-height-sm: 24px;@radio-button-color: #778CA2;@breadcrumb-icon-font-size: 16px;@pagination-font-family: "Inter";@pagination-item-bg-active: linear-gradient(to right, #5AA5FF, #006CF0);